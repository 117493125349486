<template>
  <div>
    <b-card header-tag="header">
      <b-card header-tag="header" footer-tag="footer" no-body>
        <b-card no-body>
          <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
            <b-row>
              <!-- <b-col cols="2"> Kullanıcılar </b-col> -->
              <b-col cols="3"> <strong> Toplam Kullanıcı Sayısı: {{ search_all_users.length }} </strong> </b-col>
              <b-col cols="2">
                <b-form-input v-model="filterTable" placeholder="Filtreleyebilirsiniz"></b-form-input>
              </b-col>
              <b-col cols="2" v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_all-hospitals') !== -1">
                <b-form-select v-model="list_type">
                  <option value="all_hospitals">Tümü</option>
                  <!-- <option value="this_hospital">Bu Hastane</option> -->
                  <option value="selected_hospital">Seçilen Hastane</option>
                </b-form-select>
              </b-col>
              <b-col cols="2" v-if="list_type === 'selected_hospital'">
                <b-form-select :plain="true" v-model="d_selectedHospitalGroup" @change="f_changeHospitalGroup()" required>
                  <option v-for="hospital_groups in d_hospitalGroupList" :value="hospital_groups.value">{{ hospital_groups.label }}</option>
                </b-form-select>
              </b-col>
              <b-col cols="3" v-if="list_type === 'selected_hospital' && d_selectedHospitalGroup">
                <b-form-select :plain="true" v-model="d_selectedHospitalId" @change="f_changeHospitalId()" required>
                  <option v-for="hospitals in d_hospitalList" :value="hospitals.value">{{ hospitals.label }}</option>
                </b-form-select>
              </b-col>
            </b-row>
          </b-card-header>
          <b-row>
            <b-col cols="12">
              <b-card class="card-accent-primary" header-tag="header">
                <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
                  <b-pagination v-model="d_tablePageData['current']" :total-rows="search_all_users.length" :per-page="d_tablePageData['per-page']" aria-controls="user_table"></b-pagination>
                  <b-form-checkbox class="pull-right" id="d_showPassiveUsers" v-model="d_showPassiveUsers" name="d_showPassiveUsers" value="yes" unchecked-value="no">
                    Pasif kullanıcıları da listele
                  </b-form-checkbox>
                  <a v-if="d_excelFile" :href="d_excelFile" class="pull-right" download style="color: #0f1ee8;"><img src="@/icon/2237824.png" style="border-radius: 50%; width: 3em; background-color: white" /></a>
                </b-card-header>
                <b-table id="user_table" :per-page="d_tablePageData['per-page']" :current-page="d_tablePageData['current']" thead-tr-class="/*search-patient-table-thead-class*/" head-variant="dark" striped :filter="filterTable" fixed stacked="md" ref="ref_doctorProtocolList" bordered small hover :items="search_all_users" :fields="columns" selectable select-mode="single" selectedVariant="success" @row-selected="" @row-contextmenu="" @row-clicked="" @row-dblclicked="" @row-hovered="" @row-unhovered="">
                  <template v-slot:cell(rowNumber)="row">
                    {{ ((d_tablePageData['current'] - 1) * d_tablePageData['per-page']) + row.index + 1 }}
                  </template>
                  <template v-slot:cell(username)="row">
                    {{ row.value }}
                  </template>
                  <template v-slot:cell(first_name)="row">
                    {{ row.value }}
                  </template>
                  <template v-slot:cell(last_name)="row">
                    {{ row.value }}
                  </template>
                  <template v-slot:cell(group)="row">
                    {{ row.value }}
                  </template>
                  <template v-slot:cell(created_at)="row">
                    {{ DateFormat(row.value) }}
                  </template>
                  <template v-slot:cell(update_at)="row">
                    {{ row.value ? DateFormat(row.value) : '' }}
                  </template>
                  <template v-slot:cell(is_active)="row">
                    <span :style="row.value === 'pasif' ? 'color: red; font-weight: bold;' : ''">{{ row.value }}</span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <b-button block variant="primary" title="düzenle" @click="users_edit(row.item.username)"><i class="fa fa-edit fa-1x"></i></b-button>
                  </template>
                  <template v-slot:cell(row-details)="row">
                    <b-card>
                      <ul>
                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                      </ul>
                    </b-card>
                  </template>
                </b-table>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import HospitalService from '@/services/hospital';
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from 'wisdom-config';
import {
  default as router
} from '@/router';
import { API_BASE_URL } from '@/config';
import UserService from '@/services/users';
import moment from 'moment';

export default {
  name: 'user_users',
  computed: {
    ...mapGetters({
      patient: 'patient_selected'
    })
  },
  data () {
    return {
      d_tablePageData: { 'current': 1, 'per-page': 50 },
      user: {},
      filterTable: '',
      columns: [{
        key: 'rowNumber',
        label: 'No'
      }, {
        key: 'hospital_group_id',
        label: 'Hospital',
        sortable: true,
        formatter: (value, key, item) => {
          return item.hospital_group + ' --> ' + item.hospital_id
        }
      }, {
        key: 'id',
        label: 'User ID',
        sortDirection: 'asc',
        sortable: true
      }, {
        key: 'username',
        label: 'Kullanıcı Adı',
        sortable: true
      }, {
        key: 'email',
        label: 'Email Adresi',
        sortable: true
      }, {
        key: 'first_name',
        label: 'Ad',
        sortable: true
      }, {
        key: 'last_name',
        label: 'Soyad',
        sortable: true
      }, {
        key: 'active_department',
        label: 'Aktif Departman',
        sortable: true
      }, {
        key: 'active_position',
        label: 'Aktif Pozisyon',
        sortable: true
      }, {
        key: 'active_title',
        label: 'Aktif Ünvan',
        sortable: true
      }, {
        key: 'hospital_branch_id',
        label: 'Hastane Branş ID',
        sortable: true
      }, {
        key: 'is_active',
        label: 'Aktif?',
        sortable: true
      }, {
        key: 'created_at',
        label: 'Kayıt Tarihi',
        sortable: true
      }, {
        key: 'update_at',
        label: 'Güncelleme Tarihi',
        sortable: true
      }, {
        key: 'used_promocodes',
        label: 'Promocode Listesi',
        sortable: true
      },{
        key: 'is_email_approved',
        label: 'Email Doğrulama',
        sortable: true
      }, {
        key: 'actions',
        label: 'Düzenle'
      }],
      all_users: [],
      search_all_users: [],
      list_type: 'selected_hospital',
      d_hospitalGroupList: [],
      d_hospitalList: [],
      d_tmpAllUserList: [],
      d_selectedHospitalGroup: '',
      d_selectedHospitalId: '',
      d_showPassiveUsers: 'no',
      d_excelFile: ''
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_selectedHospitalGroup = this.user.hospital_group;
    this.d_selectedHospitalId = this.user.hospital_id;
    if (this.user.permissions_result.indexOf('hospital-settings_all-hospitals') !== -1) {
      this.list_type = 'all_hospitals';
      this.f_getHospitalGroup();
    }
    this.f_getSearchAllUsers();
    /*
    UserService.get_store_users(query)
      .then(resp => {
        this.all_users = resp.data.data;
        localStorage.setItem('all_users', JSON.stringify(resp.data.data));
      });
      */
  },
  methods: {
    f_getSearchAllUsers: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12722') } });
      let query = 'type=' + this.list_type;
      query += '&hospital_group=' + this.d_selectedHospitalGroup;
      query += '&hospital_id=' + this.d_selectedHospitalId;
      query += '&include_passive=' + this.d_showPassiveUsers;
      UserService.getUsersForUserSettings(query)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.search_all_users = resp.data.result.list;
            this.d_excelFile = resp.data.result.file;
            this.d_tmpAllUserList = JSON.parse(JSON.stringify(this.search_all_users));
          } else {
            this.search_all_users = [];
          }
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        });
    },
    f_changeHospitalId: function () {
      this.f_getSearchAllUsers();
      // this.search_all_users = [];
      // for (let i in this.d_tmpAllUserList) {
      //   if (this.d_selectedHospitalGroup === this.d_tmpAllUserList[i].hospital_group && this.d_selectedHospitalId === this.d_tmpAllUserList[i].hospital_id) {
      //     this.search_all_users.push(this.d_tmpAllUserList[i]);
      //   }
      // }
    },
    f_changeHospitalGroup: function () {
      for (let i in this.d_hospitalGroupList) {
        if (this.d_hospitalGroupList[i].value === this.d_selectedHospitalGroup) {
          this.d_hospitalList = JSON.parse(JSON.stringify(this.d_hospitalGroupList[i].hospital_list));
          this.$forceUpdate();
          break;
        }
      }
    },
    f_getHospitalGroup: function () {
      HospitalService.get_all_hospitals_list()
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_hospitalGroupList = resp.data.result;
          }
        });
    },
    DateFormat: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    go_back: function () {
      router.go(-1);
    },
    go_new_user: function () {
      router.push({ path: '/user/users/new', query: {} });
    },
    users_edit: function (username) {
      router.push({ path: '/hospital/users/edit', query: { 'username': username, 'edit': true } });
    }
  },
  watch: {
    'list_type': function () {
      if (this.list_type === 'all_hospitals') {
        this.f_getSearchAllUsers();
        this.d_selectedHospitalGroup = '';
        this.d_selectedHospitalId = '';
      }
    },
    'd_showPassiveUsers': function () {
      this.f_getSearchAllUsers();
    }
  },
  components: {}
};

</script>

